import { RouteProps } from '../../../routes/AppRouter'
import { Product } from '../../../modules/product/models/Product'
import React, { useEffect, useState } from 'react'
import { useStyles } from '../Application.styles'
import { COLOR_PRIMARY } from '../../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { getSampleTypeContainer } from '../../../container/sampleType-modules'
import { SampleTypeService } from '../../../modules/sampleType/services/SampleTypeService'
import { SAMPLETYPE_SERVICE_KEY } from '../../../modules/sampleType'
import { Query } from '../../../common/api/Query'
import { SampleType } from '../../../modules/sampleType/models/SampleType'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { PatientNGSDTO } from '../../../modules/patient/models/patientForm/PatientNGSDTO'
import { PatientProduct } from 'modules/patient/models/relationship/PatientProduct'
import { emptySampleNGSDTO } from 'modules/sampleType/models/sampleForm/SampleNGSDTO'
import { format, isValid } from 'date-fns'
import {convertTimeToLocal, formatDate } from '../../../common/utils/DataFormatters'


export type SampleNGSProps = RouteProps & {
  patientProduct: PatientProduct
  product: Product
  patients: PatientNGSDTO[]
  handleChangePatientProducts: (pp: PatientProduct, i: number) => void
  index: number
  edit?: boolean
}

const sampleTypeService = getSampleTypeContainer().get<SampleTypeService>(SAMPLETYPE_SERVICE_KEY)

export const SampleNGS = (props: SampleNGSProps) => {
  const patientNumber = props.product.patientNumber || 0
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t, i18n } = useTranslation()
  const [sampleType, setSampleType] = useState<SampleType[]>([])
  const [patientProduct, setPatientProduct] = useState<PatientProduct>(props.patientProduct)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    sampleTypeService.getFilteredListDB(new Query({})).subscribe((res) => {
      setSampleType(res.items)
      setIsLoading(false)
    })
  }, [])

  const handleChangePatients = (e: any, type: string, index: number) => {
    if (props.patients) {
      let p = props.patients[index]
      let pp = props.patientProduct

      if (p && p._sample) {
        if (type == '_type') {
          p._sample._typeSample = e.target.value
          p._sample.typeSample = e.target.value
        } else if (type == '_codeSample') {
          p._sample._codeSample = e.target.value
          p._sample.codeSample = e.target.value
        } else if (type == 'language') {
          pp.language = e.target.value
          pp._language = e.target.value
        }

        let patients = props.patients
        patients[index] = p

        let newProduct = Object.assign({ ...patientProduct }, { patientNGS: patients })
        //@ts-ignore
        setPatientProduct(newProduct)
        //@ts-ignore
        props.handleChangePatientProducts(newProduct, props.index)
      } else {
        let p = props.patientProduct.patientNGS[index]
        let pp = props.patientProduct

        if (p && p._sample) {
          if (type == '_type') {
            p._sample._typeSample = e.target.value
            p._sample.typeSample = e.target.value
          } else if (type == '_codeSample') {
            p._sample._codeSample = e.target.value
            p._sample.codeSample = e.target.value
          } else if (type == 'language') {
            pp.language = e.target.value
            pp._language = e.target.value
          }

          let patients = props.patients
          patients[index] = p

          let newProduct = Object.assign({ ...patientProduct }, { patientNGS: patients })
          //@ts-ignore
          setPatientProduct(newProduct)
          //@ts-ignore
          props.handleChangePatientProducts(newProduct, props.index)
        }
      }
    }
  }

  const handleChangePatientsDate = (d: Date, index: number) => {
    if (props.patients) {
      let p = props.patients[index]

      if (!p._sample) {
        //@ts-ignore
        p._sample = emptySampleNGSDTO()
      }

      p._sample._extractionDate = d

      let patients = props.patients
      patients[index] = p

      let newProduct = Object.assign({ ...patientProduct }, { patientNGS: patients })
      //@ts-ignore
      props.handleChangePatientProducts(newProduct, props.index)
      //@ts-ignore
      setPatientProduct(newProduct)
    }
  }


  return (
    <>
      {!isLoading ? (
        <Grid container direction="column">
          {[...Array(patientNumber)].map((_, index) => (
            <Grid container spacing={4} key={index} style={{ marginTop: '40px' }}>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '18px' }}>
                {t('dateAboutSample') + ' ' + (index + 1) + ' ' + t('for') + ' ' + (i18n.language ==
                'es'
                  ? props.product?.nameES
                  : props.product?.nameEN)}
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="typeSampleLabel">{t('typeSample') + '*'}</InputLabel>
                  <Select
                    labelId={'typeSampleLabel'}
                    id={'type'}
                    name={'type'}
                    disabled={props.edit == false}
                    required
                    onChange={(e) => handleChangePatients(e, '_type', index)}
                    value={
                      patientProduct.patientNGS &&
                      patientProduct.patientNGS[index]?._sample?._typeSample
                    }>
                    {sampleType.map((elementST, indexST) => {
                      return (
                        <MenuItem key={indexST} value={elementST.idOdoo ? elementST.idOdoo : ''}>
                          {(i18n.language == 'es' ? elementST?.nameES : elementST?.nameEN)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="code"
                  variant="filled"
                  label={t('codeSample')}
                  className={classes.textField}
                  disabled={props.edit == false}
                  fullWidth
                  value={
                    patientProduct.patientNGS &&
                    patientProduct.patientNGS[index]?._sample?._codeSample
                  }
                  onChange={(e) => handleChangePatients(e, '_codeSample', index)}
                />
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ width: '100%' }}
                    id={`dob-${index}`}
                    autoOk
                    inputVariant={'outlined'}
                    disabled={props.edit == false}
                    format="dd/MM/yyyy"
                    value={
                      (patientProduct.patientNGS &&
                       convertTimeToLocal( patientProduct.patientNGS[index]?._sample?._extractionDate)) ||
                      null
                    }
                    onChange={(date) => date && isValid(date) && handleChangePatientsDate(formatDate(date), index)}
                    required={true}
                    size={'small'}
                    label={t('doe')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <CircularProgress style={{ alignSelf: 'center' }} />
      )}
    </>
  )
}
