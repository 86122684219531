export const LocaleDateFilter = (
  date: Date,
  locale: string = "es-ES"
): string => {
  return new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(date);
};

export  const convertTimeToLocal = (date: Date) => {
  return new Date(date?.toString().substring(0,date.toString().length - 1))
}

export const formatDate = (date: Date) =>{
      
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const dateInUTC = new Date(Date.UTC(year, month, day));

  return dateInUTC
}
