import { RouteProps } from '../../../routes/AppRouter'
import { Product } from '../../../modules/product/models/Product'
import {
  emptyPatientNGSDTO,
  fromPatientNGSModel,
  PatientNGSDTO,
  toModelPatientNGS,
} from '../../../modules/patient/models/patientForm/PatientNGSDTO'
import { useEffect, useState } from 'react'
import { useStyles } from '../Application.styles'
import { COLOR_PRIMARY } from '../../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import { PatientNGS } from '../../../modules/patient/models/patientForm/PatientNGS'
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { FormType, userGenders } from '../../../common/enums/Enums'
import { getPatientContainer } from 'container/patient-modules'
import { getClientContainer } from 'container/client-modules'
import { PatientService } from 'modules/patient/services/PatientService'
import { ClientService } from 'modules/clients/services/ClientService'
import { PATIENT_SERVICE_KEY } from 'modules/patient'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { Query, QueryParam } from 'common/api/Query'
import { emptySampleNGSDTO, toModel } from 'modules/sampleType/models/sampleForm/SampleNGSDTO'
import { emptySampleDTO } from 'modules/sample/models/SampleDTO'
import { v4 as uuidv4 } from 'uuid'
import { format, isValid } from 'date-fns'
import {convertTimeToLocal, formatDate } from '../../../common/utils/DataFormatters'


export type FormNGSProps = RouteProps & {
  patients: PatientNGSDTO[]
  productIndex: number
  handleChangePatients: (productIndex: number, patients: any[], type: FormType) => void
  product: Product
  type?: string
  edit?: boolean
  clientIDDoingTheRequest: string
}

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export const PatientFormNGS = (props: FormNGSProps) => {
  const patientNumber = props.product ? props.product.patientNumber : 0
  const classes = useStyles({ color: COLOR_PRIMARY })
  const [patients, setPatients] = useState<PatientNGSDTO[]>(props.patients || [])
  const { t, i18n } = useTranslation()
  const [selectedPatientID, setSelectedPatientID] = useState<string[]>([])
  const [previousPatients, setPreviousPatients] = useState<PatientNGS[]>([])

  useEffect(() => {
    let patientsAux: PatientNGSDTO[] = []
    let start = 0

    for (let i = start; i < patientNumber; i++) {
      if (props.patients?.length >= i && props.patients[i]) {
        let filtered = previousPatients.filter((p) => p.id == props.patients[i]._id)
        let auxSelected: any[] = []

        if (filtered?.length > 0) {
          if (patientNumber > 0) {
            auxSelected[0] = filtered[0].id
            patientsAux[0] = fromPatientNGSModel(filtered[0])
          }

          setSelectedPatientID(auxSelected)
        }
        patientsAux[i] = props.patients[i]
      } else {
        patientsAux[i] = emptyPatientNGSDTO()
      }
    }

    setPatients(patientsAux)
  }, [previousPatients])

  useEffect(() => {
    if (props.patients?.length > 0) {
      let auxSelected: any[] = []
      props.patients.forEach((p, i) => {
        auxSelected[i] = p._id
      })
      setSelectedPatientID(auxSelected)
    } else {
      let auxSelected: any[] = []
      Array(patientNumber).forEach((_, i) => {
        auxSelected[i] = ''
      })
      setSelectedPatientID(auxSelected)
    }
  }, [])

  useEffect(() => {
    clientService.getByID(props.clientIDDoingTheRequest).subscribe(async (res) => {
      if (res) {
        let client = res

        let patients = await patientService
          .getFilteredList(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let citogenicPatientsItemList = await patientService
          .getFilteredListCitogenicArray(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let microvePatients = await patientService
          .getFilteredListMicroVE(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let ngsPatientsItemList = await patientService
          .getFilteredListNGS(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let ngsPatients: any[] = ngsPatientsItemList?.items || []

        ngsPatients.forEach((i) => {
          i.priority = true
        })

        let oncologyPatients = await patientService
          .getFilteredListOncology(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        let pgtPatients = await patientService
          .getFilteredListPGT(
            new Query({
              query: [new QueryParam('clientID', client.id || '')],
            })
          )
          .toPromise()

        const resultArray = [
          ...(patients?.items || []),
          ...(citogenicPatientsItemList?.items || []),
          ...(microvePatients?.items || []),
          ...ngsPatients,
          ...(oncologyPatients?.items || []),
          ...(pgtPatients?.items || []),
        ]

        let personasFiltradas: any[] = filtrarPersonas(resultArray)

        if (window.location.pathname.includes('new')) {
          personasFiltradas.forEach((persona) => {
            //@ts-ignore
            persona._sample = toModel(emptySampleDTO())
            //@ts-ignore
            persona.sample = toModel(emptySampleDTO())
          })
        }

        setPreviousPatients(personasFiltradas)
      }
    })
  }, [])

  function filtrarPersonas(personas: any[]): any[] {
    const personasFiltradas: { [key: string]: any } = {}

    personas.forEach((persona) => {
      if (personasFiltradas[persona.firstName + persona.lastName]) {
        if (persona.priority) {
          personasFiltradas[persona.firstName + persona.lastName] = persona
        }
      } else {
        personasFiltradas[persona.firstName + persona.lastName] = persona
      }
    })

    return Object.values(personasFiltradas)
  }

  const getEmptyPatients = (): PatientNGSDTO[] => {
    const patients: PatientNGSDTO[] = []
    if (patientNumber) {
      for (let i = 0; i < patientNumber; i++) {
        patients.push(emptyPatientNGSDTO())
      }
    }
    return patients
  }

  useEffect(() => {
    if (
      props.type != 'view' &&
      ((props.patients !== null && props.patients?.length == 0) || props.patients == null)
    ) {
      props.handleChangePatients(props.productIndex, getEmptyPatients(), FormType.TypeFormNGS)
    }
  }, [])

  useEffect(() => {
    if (props.type == 'view') {
      props.handleChangePatients(props.productIndex, props.patients, FormType.TypeFormNGS)
    }
  }, [])

  useEffect(() => {
    if (props.type == 'view') {
      props.handleChangePatients(props.productIndex, props.patients, FormType.TypeFormNGS)
    }
  }, [props.type])

  const handlePatientChange = (
    index: number,
    field: keyof PatientNGS,
    value: string | Date | boolean | number
  ) => {
    const newPatients = [...patients]
    const patientToUpdate = newPatients[index]
    const updatedPatient = { ...patientToUpdate, [field]: value }

    if (!updatedPatient._id) {
      updatedPatient._id = uuidv4()
    }

    newPatients[index] = updatedPatient
    setPatients(newPatients)
    props.handleChangePatients(props.productIndex, newPatients, FormType.TypeFormNGS)
  }

  const handleSetSelectedValue = (index: number, value: string) => {
    let patientsAux = previousPatients.filter((p) => p.id === value)
    let newPatients = [...patients]

    if (patientsAux?.length > 0) {
      let patient = patientsAux[0]

      let auxElement = {
        _id: patient.id,
        _firstName: patient.firstName,
        _lastName: patient.lastName,
        _dob: patient.dob,
        _nhc: patient.nhc,
        _nss: patient.nss,
        _province: patient.province,
        _zipCode: patient.zipCode,
        _phone: patient.phone,
        _email: patient.email,
        _indication: patient.indication,
        _clinicHistory: patient.clinicHistory,
        _sex: patient.sex,
        _sample: patient._sample ? patient._sample : toModel(emptySampleNGSDTO()),
      }

      newPatients.splice(index, 1, auxElement)
    } else {
      newPatients.splice(index, 1, toModelPatientNGS(emptyPatientNGSDTO()))
    }

    setPatients(newPatients)
    props.handleChangePatients(props.productIndex, newPatients, FormType.TypeFormNGS)

    let auxSelected = [...selectedPatientID]
    auxSelected[index] = value
    setSelectedPatientID(auxSelected)
  }

  return (
    <Grid container direction="column">
      {[...Array(patientNumber)].map((_, index) => (
        <Grid container spacing={4} key={index} style={{ marginTop: '40px' }}>
          <Grid item xs={12} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '18px' }}>
          {t('fillPatientData') + ' ' + (index + 1) + ' ' + t('for') + ' ' + (i18n.language == "es" ? props.product.nameES : props.product.nameEN)}
          </Grid>
          {props.edit !== false && (
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="typeSampleLabel">{t('selectPatient')}</InputLabel>
                <Select
                  labelId={'typeSampleLabel'}
                  id={'type'}
                  name={'type'}
                  //@ts-ignore
                  onChange={(e) => handleSetSelectedValue(index, e.target.value)}
                  style={{ width: '50%' }}
                  value={selectedPatientID?.length > index ? selectedPatientID[index] : ''}>
                  {previousPatients.map((patient, indexST) => {
                    return (
                      <MenuItem key={indexST} value={patient.id}>
                        {patient.firstName + ' ' + patient.lastName}
                      </MenuItem>
                    )
                  })}
                  <MenuItem key={''} value={''}>
                    {t('notSelect')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              id={`firstName-${index}`}
              label={t('name')}
              variant="filled"
              disabled={props.edit === false}
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._firstName) || ''}
              onChange={(e) => handlePatientChange(index, '_firstName', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`lastName-${index}`}
              label={t('lastName')}
              disabled={props.edit === false}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._lastName) || ''}
              onChange={(e) => handlePatientChange(index, '_lastName', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                id={`dob-${index}`}
                autoOk
                inputVariant={'outlined'}
                disabled={props.edit === false}
                format="dd/MM/yyyy"
                value={(patients && convertTimeToLocal(patients[index]?._dob) )|| null}
                onChange={(date) => date && isValid(date) && handlePatientChange(index, '_dob', formatDate(date))}
                required={true}
                size={'small'}
                label={t('dob')}
              />
            </MuiPickersUtilsProvider>
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                defaultValue={new Date()}
                disabled={props.edit === false}
                format="dd/MM/yyyy"
                value={(patients && patients[index]?._dob) || null}
                onChange={(date) => date && handlePatientChange(index, '_dob', new Date(date))}
     
                label={t('dob')}
              />
            </LocalizationProvider> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`nhc-${index}`}
              disabled={props.edit === false}
              label={t('nhc')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._nhc) || ''}
              onChange={(e) => handlePatientChange(index, '_nhc', e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`nss-${index}`}
              disabled={props.edit === false}
              label={t('nss')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._nss) || ''}
              onChange={(e) => handlePatientChange(index, '_nss', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="inherit-label">{t('gender') + '*'}</InputLabel>
              <Select
                labelId="gender-label"
                id="gender"
                fullWidth={props.edit === false}
                style={{ textAlign: 'left' }}
                value={(patients && patients[index]?._sex) || ''}
                onChange={(e) => handlePatientChange(index, '_sex', e.target.value as string)}
                required
                label={t('gender')}>
                {Object.entries(userGenders())?.map(([key, value]) => (
                  <MenuItem value={key}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`province-${index}`}
              disabled={props.edit === false}
              label={t('province')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._province) || ''}
              onChange={(e) => handlePatientChange(index, '_province', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`zipCode-${index}`}
              disabled={props.edit === false}
              label={t('zipCode')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._zipCode) || ''}
              onChange={(e) => handlePatientChange(index, '_zipCode', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`phone-${index}`}
              disabled={props.edit === false}
              label={t('phone')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._phone) || ''}
              onChange={(e) => handlePatientChange(index, '_phone', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id={`email-${index}`}
              disabled={props.edit === false}
              label={t('email')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={(patients && patients[index]?._email) || ''}
              onChange={(e) => handlePatientChange(index, '_email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id={`indication-${index}`}
              disabled={props.edit === false}
              label={t('indication')}
              onChange={(event) => handlePatientChange(index, '_indication', event.target.value)}
              multiline
              rows={4}
              value={(patients && patients[index]?._indication) || ''}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              id={`clinicHistory-${index}`}
              disabled={props.edit === false}
              label={t('clinicHistory')}
              onChange={(event) => handlePatientChange(index, '_clinicHistory', event.target.value)}
              multiline
              rows={4}
              value={(patients && patients[index]?._clinicHistory) || ''}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
