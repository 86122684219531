import { Container, IInit } from "../../../common/container/Container";
import { Observable, of } from "rxjs";
import { toModel } from "../../client2Requests/models/Client2RequestDTO";
import { HTTP_CLIENT_KEY, IHTTPClient } from "../../../common/api/HTTPClient";
import { IStatusService } from "../../../common/status/StatusService";
import { STATUS_SERVICE_KEY } from "../../../container/app";
import { catchError, map } from "rxjs/operators";
import { Client2RequestDTO } from "../models/Client2RequestDTO";
import { Client2Request } from "../models/Client2Request";
import { Client2RequestContainerConfig } from "../container";
import { emptyList, ItemList } from "../../../common/models/ItemList";
import { Wizard } from "../models/Wizard";
import { WizardDTO, toModel as wizardModel } from "../models/WizardDTO";
import { PatientProduct } from "../../patient/models/relationship/PatientProduct";
import { PatientProductDTO } from "../../patient/models/relationship/PatientProductDTO";
import { GenesDTO } from "modules/genes/models/GenesDTO";
import { RequestView, RequestViewDTO, toModel as toModelView } from "../models/RequestsView";

export interface IClient2RequestApi extends IInit {
    getByID(id: string): Observable<Client2Request | undefined>

    byUserIDPostAll(id: string, search: string, status: string): Observable<ItemList<Client2Request>>
    
    add(e: Client2RequestDTO): Observable<Client2Request | undefined>

    update(e: Client2RequestDTO): Observable<Client2Request | undefined>

    delete(id: string): Observable<boolean>

    getByUserID(id: string): Observable<ItemList<Client2Request>>

    byUserIDPost(id: string, offset: number, limit: number, search: string, sortField: string, sortDirection: boolean | undefined, status: string): Observable<ItemList<Client2Request>>

    getByClientID(id: string): Observable<ItemList<Client2Request>>

    saveWizard(e: WizardDTO): Observable<Wizard | undefined>

    saveWizardDraft(e: WizardDTO): Observable<Wizard | undefined>

    sendPatientProduct(e: PatientProductDTO[]): Observable<Request[] | undefined>

    sendPatientProductDraft(e: PatientProductDTO[]): Observable<PatientProductDTO | undefined>

    sendPatientProductDraftAgain(e: PatientProductDTO[], id: string): Observable<PatientProductDTO | undefined>

    getWizardByID(id: string): Observable<PatientProduct[]>

    updateWizard(id: string, pp: PatientProductDTO[]): Observable<PatientProductDTO | undefined>

    createTemplate(r: Request, pp: PatientProduct): void

    getGenesByRequestAndProduct(requestID: string, productID: string): Observable<GenesDTO[] | undefined>

    getCodeSamples(id: string): Observable<ItemList<string | undefined>>

    getRequestsView(search: string, userID: string , sortField: string, sortDirection: boolean, status: string,offset: number, limit: number): Observable<ItemList<RequestView>>
}

export class Client2RequestApi implements IClient2RequestApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as Client2RequestContainerConfig).moduleFullUrl
    }

    add(e: Client2RequestDTO): Observable<Client2Request | undefined> {
        return this._httpClient.post<Client2Request>({ url: this._url, body: e }).pipe(
            map<Client2RequestDTO, Client2Request>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(false)
            })
        )
    }

    getByID(id: string): Observable<Client2Request | undefined> {
        return this._httpClient.get<Client2Request>({ url: `${this._url}/${id}` }).pipe(
            map<Client2RequestDTO, Client2Request>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    update(e: Client2RequestDTO): Observable<Client2Request | undefined> {
        return this._httpClient.put<Client2Request>({ url: this._url, body: e }).pipe(
            map<Client2RequestDTO, Client2Request>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getByUserID(id: string): Observable<ItemList<Client2Request>> {
        return this._httpClient.get<ItemList<Client2Request>>({ url: `${this._url}/${id}` }).pipe(
            map<ItemList<Client2RequestDTO>, ItemList<Client2Request>>((dto) => {
                const itemList = emptyList<Client2Request>()
                itemList.items = dto.items.map((d) => toModel(d))
                itemList.count = dto.count
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<Client2Request>())
            })
        )
    }

    byUserIDPost(id: string, offset: number, limit: number, search: string, sortField: string, sortDirection: boolean | undefined, status: string): Observable<ItemList<Client2Request>> {
        return this._httpClient.post<ItemList<Client2Request>>({ url: this._url + "/byUserID", body: {
            userid: id, 
            offset: offset,
            limit:  limit,
            search: search,
            sortField: sortField,
            sortDirection: sortDirection,
            status: status,
        } }).pipe(
            map<ItemList<Client2RequestDTO>, ItemList<Client2Request>>((dto) => {
                const itemList = emptyList<Client2Request>()
                itemList.items = dto.items.map((d) => toModel(d))
                itemList.count = dto.count
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<Client2Request>())
            })
        )
    }

    byUserIDPostAll(id: string, search: string, status: string): Observable<ItemList<Client2Request>> {
        return this._httpClient.post<ItemList<Client2Request>>({ url: this._url + "/byUserIDAll", body: {
            userid: id, 
            search: search,
            status: status
        } }).pipe(
            map<ItemList<Client2RequestDTO>, ItemList<Client2Request>>((dto) => {
                const itemList = emptyList<Client2Request>()
                itemList.items = dto.items.map((d) => toModel(d))
                itemList.count = dto.count
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<Client2Request>())
            })
        )
    }

    getByClientID(id: string): Observable<ItemList<Client2Request>> {
        return this._httpClient.get<ItemList<Client2Request>>({ url: `${this._url}/client/${id}` }).pipe(
            map<ItemList<Client2RequestDTO>, ItemList<Client2Request>>((dto) => {
                const itemList = emptyList<Client2Request>()
                itemList.items = dto.items.map((d) => toModel(d))
                itemList.count = dto.count
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<Client2Request>())
            })
        )
    }

    saveWizard(e: WizardDTO): Observable<Wizard | undefined> {
        return this._httpClient.post<Wizard>({ url: this._url + "/saveWizard", body: e }).pipe(
            map<WizardDTO, Wizard>((d) => wizardModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    saveWizardDraft(e: WizardDTO): Observable<Wizard | undefined> {
        return this._httpClient.post<Wizard>({ url: this._url + "/saveWizardDraft", body: e }).pipe(
            map<WizardDTO, Wizard>((d) => wizardModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    sendPatientProduct(e: PatientProductDTO[]): Observable<Request[] | undefined> {
        return this._httpClient.post<Request[]>({ url: this._url + "/patientProduct", body: e }).pipe(
            map<Request[], Request[]>((d) => {
                return d;
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )

    }

    sendPatientProductDraft(e: PatientProductDTO[]): Observable<PatientProductDTO | undefined> {
        return this._httpClient.post<PatientProductDTO>({ url: this._url + "/patientProductDraft", body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    sendPatientProductDraftAgain(e: PatientProductDTO[], id: string): Observable<PatientProductDTO | undefined> {
        return this._httpClient.put<PatientProductDTO>({
            url: this._url + "/patientProductDraft/" + id, body: e
        }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getWizardByID(id: string): Observable<PatientProduct[]> {
        return this._httpClient.get<PatientProduct[]>({ url: `${this._url}/getWizard/${id}` }).pipe(
            map((dto) => {
                dto?.forEach((p) => {
                    //@ts-ignore
                    p.product.genes = p.product._genes
                    //@ts-ignore
                    p.product.id = p.product._id
                    //@ts-ignore
                    p.product.idOdoo = p.product._idOdoo
                    //@ts-ignore
                    p.product.name = p.product._name
                    //@ts-ignore
                    p.product.patientNumber = p.product._patientNumber
                    //@ts-ignore
                    p.product.price = p.product._price
                    //@ts-ignore
                    p.product.reference = p.product._reference
                    //@ts-ignore
                    p.product.samplePerPatient = p.product._samplePerPatient
                    //@ts-ignore
                    p.product.type = p.product._type
                    //@ts-ignore
                    p.product.typeConsent = p.product._typeConsent
                    //@ts-ignore
                    p.product.typeForm = p.product._typeForm

                    p?.patientMicroVE?.forEach((a) => {
                        //@ts-ignore
                        a.abortions = a._abortions
                        //@ts-ignore
                        a.age = a._age
                        //@ts-ignore
                        a.dob = a._dob
                        //@ts-ignore
                        a.email = a._email
                        //@ts-ignore
                        a.firstName = a._firstName
                        //@ts-ignore
                        a.id = a._id
                        //@ts-ignore
                        a.ivfFailure = a._ivfFailure
                        //@ts-ignore
                        a.lastName = a._lastName
                        //@ts-ignore
                        a.nhc = a._nhc
                        //@ts-ignore
                        a.nhc = a._nhc
                        //@ts-ignore
                        a.sample = a._sample
                        //@ts-ignore
                        a.phone = a._phone
                        //@ts-ignore
                        a.dni = a._dni
                    })

                    p?.patientNulls
                        ?.forEach((a) => {
                            //@ts-ignore
                            a.creationDate = a._creationDate
                            //@ts-ignore
                            a.firstName = a._firstName
                            //@ts-ignore
                            a.geneticDiseaseHistory = a._geneticDiseaseHistory
                            //@ts-ignore
                            a.id = a._id
                            //@ts-ignore
                            a.lastName = a._lastName
                            //@ts-ignore
                            a.sample = a._sample
                        })

                    p?.patientNGS
                        ?.forEach((a) => {
                            //@ts-ignore
                            a.clinicHistory = a._clinicHistory
                            //@ts-ignore
                            a.dob = a._dob
                            //@ts-ignore
                            a.email = a._email
                            //@ts-ignore
                            a.firstName = a._firstName
                            //@ts-ignore
                            a.id = a._id
                            //@ts-ignore
                            a.indication = a._indication
                            //@ts-ignore
                            a.lastName = a._lastName
                            //@ts-ignore
                            a.nhc = a._nhc
                            //@ts-ignore
                            a.nss = a._nss
                            //@ts-ignore
                            a.phone = a._phone
                            //@ts-ignore
                            a.province = a._province
                            //@ts-ignore
                            a.sample = a._sample
                            //@ts-ignore
                            a.zipCode = a._zipCode
                        })

                    p?.patientPGTs
                        ?.forEach((a) => {
                            //@ts-ignore
                            a.id = a._id
                            //@ts-ignore
                            a.firstName = a._firstName
                            //@ts-ignore
                            a.lastName = a._lastName
                            //@ts-ignore
                            a.dob = a._dob
                            //@ts-ignore
                            a.nhcOrDni = a._nhcOrDni
                            //@ts-ignore
                            a.karyotype = a._karyotype
                            //@ts-ignore
                            a.indication = a._indication
                            //@ts-ignore
                            a.clinicHistory = a._clinicHistory
                            //@ts-ignore
                            a.sample = a._sample

                            //@ts-ignore
                            a.sample.typeSample = a.sample._typeSample
                            //@ts-ignore
                            a.sample.extractionDate = a.sample._extractionDate
                            //@ts-ignore
                            a.sample.codeSample = a.sample._codeSample
                            //@ts-ignore
                            a.sample.idOdoo = a.sample._idOdoo
                        })
                })

                return dto
            }),
            //@ts-ignore
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return []
            })
        )
    }

    updateWizard(id: string, pp: PatientProductDTO[]): Observable<PatientProductDTO | undefined> {
        return this._httpClient.put<PatientProductDTO>({ url: this._url + "/updatePatientProduct/" + id, body: pp }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    createTemplate(r: Request, pp: PatientProduct): void {
        this._httpClient.post({
            url: process.env.REACT_APP_BACKEND_URL + '/template', body: ({
                request: r,
                patientProduct: pp
            })
        })
    }

    getGenesByRequestAndProduct(requestID: string, productID: string): Observable<GenesDTO[] | undefined> {
       let obj = {
        requestID: requestID,
        productID: productID,
    }
       return this._httpClient.post<GenesDTO[]>({ url: this._url + "/getGenesByRequestAndProduct", body:  obj})
    }

    getCodeSamples(id: string): Observable<ItemList<string | undefined>> {
        return this._httpClient.get<ItemList<string | undefined>>({ url: `${this._url}/codeSamplesByUserID/${id}` }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<string | undefined>())
            })
        )
    }
    
    getRequestsView(search: string, userID: string , sortField: string, sortDirection: boolean, status: string, offset: number, limit: number): Observable<ItemList<RequestView>> {
        const body: any = {};
    
    if (search) {
        body.search = search;
    }
    if (userID) {
        body.userID = userID;
    }
    if (sortField && sortField) {

       body.sorts = [{ field: sortField, desc: sortDirection }]
     
    }
    if (status){
        body.status = status
    }
    if (limit) {
        body.pager = { offset: offset, limit: limit }}
     
        return this._httpClient.post<ItemList<RequestView>>({ url: `${this._url}/requests/view`, body:
            body
         }).pipe(
            map<ItemList<RequestViewDTO>, ItemList<RequestView>>((dto) => {
                const itemList = emptyList<RequestView>()
                itemList.items = dto.items.map((d) => toModelView(d))
                itemList.count = dto.count
                return itemList
            } ),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<RequestView>())
            })
        )
    }
}
