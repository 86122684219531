import { COLOR_APPLICATIONS } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Sort } from '../../components/table/types'
import refershIcon from '../../assets/table-icons/refresh-icon.svg'
import addIcon from '../../assets/table-icons/add-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { navigate } from '@reach/router'
import {
  URL_APPLICATION_TEMPLATE,
  URL_APPLICATION_TEMPLATE_NEW,
} from '../../routes/routes-constants'
import { Client2Request } from '../../modules/client2Requests/models/Client2Request'
import { getPatientContainer } from 'container/patient-modules'
import { PatientService } from 'modules/patient/services/PatientService'
import { PATIENT_SERVICE_KEY } from 'modules/patient'
import { Grid, TextField } from '@material-ui/core'
import { getAuthContainer } from 'container/auth-modules'
import { AuthService } from 'modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from 'modules/auth'

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

type DetailProps = {
  id: string
}

export const Table = (props: DetailProps) => {
  const { t } = useTranslation()
  const [pager, setPager] = useState<Pager>()
  const [items, setItems] = useState<any[]>([])
  const [sort, setSort] = useState<SortParam<Client2Request>>({
    field: 'firstName',
    desc: true,
  })
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(5)
  const [allItems, setAllItems] = useState<any[]>([])
  const [patient, setPatient] = useState<any>()
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [geneticDiseaseHistory, setGeneticDeseaseHistory] = useState<string>('')
  const loggedUser = authService.get()

  const getPatient = async () => {
    let patients = await patientService
      .getFilteredList(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (patients && patients?.items?.length > 0) {
      setGeneticDeseaseHistory(patients?.items[0].geneticDiseaseHistory)
      setPatient(patients?.items[0])
    }
    let citogenicPatients = await patientService
      .getFilteredListCitogenicArray(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (citogenicPatients && citogenicPatients?.items?.length > 0) {
      setPatient(citogenicPatients?.items[0])
    }

    let microvePatients = await patientService
      .getFilteredListMicroVE(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (microvePatients && microvePatients?.items?.length > 0) {
      setPhone(microvePatients?.items[0].phone)
      setEmail(microvePatients?.items[0].email)
      setPatient(microvePatients?.items[0])
    }

    let ngsPatients = await patientService
      .getFilteredListNGS(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (ngsPatients && ngsPatients?.items?.length > 0) {
      setGeneticDeseaseHistory(ngsPatients.items[0].clinicHistory)
      setPhone(ngsPatients?.items[0].phone)
      setEmail(ngsPatients?.items[0].email)
      setPatient(ngsPatients?.items[0])
    }

    let oncologyPatients = await patientService
      .getFilteredListOncology(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (oncologyPatients && oncologyPatients?.items?.length > 0) {
      setGeneticDeseaseHistory(oncologyPatients.items[0].clinicHistory)
      setPhone(oncologyPatients?.items[0].phone)
      setPatient(oncologyPatients?.items[0])
    }

    let pgtPatients = await patientService
      .getFilteredListPGT(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (pgtPatients && pgtPatients?.items?.length > 0) {
      setGeneticDeseaseHistory(pgtPatients.items[0].clinicHistory)
      setPatient(pgtPatients?.items[0])
    }
  }

  useEffect(() => {
    getPatient()
  }, [])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  useEffect(() => {
    if (pager && allItems?.length > 0) {
      let start = (pager.rowsPerPage || 0) * pager.page
      let end = start + (pager.rowsPerPage || 0)
      setItems(allItems.slice(start, end))
    }
  }, [pager, allItems])

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    patientService
      .getRequestsByPatientID(
        new Query({
          query: [new QueryParam('id', props.id)],
        })
      )
      .subscribe((res) => {
        setAllItems(res)
        setCount(res?.length || 0)
      })
  }, [])

  const fields: Field<any>[] = [
    {
      searchable: true,
      label: t('product'),
      name: 'productName',
      sortable: false,
    },
    {
      searchable: true,
      label: t('date'),
      name: 'date',
      sortable: false,
      renderFunc: (field, item) =>
         new Date(item.date.substring(0,item.date.toLocaleString().length - 1))
            .toLocaleString()
            .substring(0, new Date(item.date).toLocaleString().length - 3) + ' h'
    },
    {
      searchable: true,
      label: t('codeSample'),
      name: 'idOdoo',
      sortable: false,
      renderFunc: (field, item) => item.newID ? item.newID : item.idOdoo
    },
  ]

  const useTemplate = (c2r: any) => navigate(URL_APPLICATION_TEMPLATE.replace(':id', `${c2r.id}`))
  const useTemplateNew = (c2r: any) =>
    navigate(URL_APPLICATION_TEMPLATE_NEW.replace(':id', `${c2r.id}`))

  const actions: Actions<any> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: useTemplate,
        icon: refershIcon,
        label: t('useTempplate'),
        hidden: (item) => item.clinicID != loggedUser.clinicID
      },
      {
        handler: useTemplateNew,
        icon: addIcon,
        label: t('new'),
        hidden: (item) => item.clinicID != loggedUser.clinicID
      },
      {
        handler: useTemplateNew,
        icon: addIcon,
        label: t('downloads'),
      },
    ],
  }

  const sortable: Sort<Client2Request> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {},
  }

  return (
    <>
      <Grid container spacing={4} style={{ marginTop: '40px', marginBottom: '30px' }}>
        <Grid item xs={6}>
          <TextField
            disabled={true}
            label={t('firstName')}
            variant="filled"
            fullWidth
            value={patient?.firstName}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={true}
            label={t('lastName')}
            variant="filled"
            fullWidth
            value={patient?.lastName}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={true}
            label={t('phone')}
            variant="filled"
            fullWidth
            value={phone}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={true}
            label={t('email')}
            variant="filled"
            fullWidth
            value={email}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('geneticDiseaseHistory')}
            disabled={true}
            multiline
            rows={4}
            value={geneticDiseaseHistory}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <AppTable
        styleHeader={{ color: COLOR_APPLICATIONS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'request'}
        pager={pager}
        sort={sortable}
      />
    </>
  )
}
