import { COLOR_APPLICATIONS } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useRef, useState } from 'react'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { RequestQuery } from '../../modules/request/models/Request'
import { navigate } from '@reach/router'
import { URL_APPLICATION_EDIT } from '../../routes/routes-constants'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'
import { getClient2RequestContainer } from '../../container/client2request-modules'
import { Client2RequestService } from '../../modules/client2Requests/services/Client2RequestService'
import { CLIENT2REQUEST_SERVICE_KEY } from '../../modules/client2Requests'
import { Client2Request } from '../../modules/client2Requests/models/Client2Request'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { downloadFile } from '../../common/files/file'
import { getRequestContainer } from 'container/request-modules'
import { RequestService } from 'modules/request/services/RequestService'
import { REQUEST_SERVICE_KEY } from 'modules/request'
import { Button, Modal } from '@material-ui/core'
import { Address } from 'modules/address/models/Address'
import { ModalAddress } from './ModalAddress'
import { useSnackbar } from 'notistack'
import { AddressDTO, emptyAddressDTO } from 'modules/address/models/AddressDTO'
import { v4 as uuidv4 } from 'uuid'
import { Client, ClientQuery } from 'modules/clients/models/Client'
import { getClientContainer } from 'container/client-modules'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { ClientService } from 'modules/clients/services/ClientService'
import { RequestView, RequestViewDTO } from 'modules/client2Requests/models/RequestsView'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const client2RequestService = getClient2RequestContainer().get<Client2RequestService>(
  CLIENT2REQUEST_SERVICE_KEY
)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const requestService = getRequestContainer().get<RequestService>(REQUEST_SERVICE_KEY)
const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export const Table = () => {
  const { t } = useTranslation()
  const loggedUser = authService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
 
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [items, setItems] = useState<RequestView[]>([])
 // const [allItems, setAllItems] = useState<Client2Request[]>([])
  const [allItems, setAllItems] = useState<RequestView[]>([])
  const [searcher, setSearcher] = useState<SearchValue<any>[]>([
    {
        name: 'request',
        label: t('search'),
    },{
      name: 'status',
      label: t('status'),
    },
]);
  





  const [address, setAddress] = useState<AddressDTO>(emptyAddressDTO())
  const [sort, setSort] = useState<SortParam<RequestQuery>>({
    field: 'date',
    desc: true,
  })

  const [requestsSendNacex, setRequestsToSendNacex] = useState<any[]>([])
  const [nacexModalOpened, setNacexModalOpened] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [client, setClient] = useState<Client>()
  const [selectedStatus, setSelectedStatus] = useState<string>('')


  useEffect(() => {
    client2RequestService.getRequestsView("",loggedUser.id,"", false, "", page * itemsPerPage,
      1000000).subscribe((res) => {
      setAllItems(res.items)
      setCount(res.count)
    }
    )

  },[] )

  useEffect(() => {
    if (loggedUser) {
      clientService
        .getFilteredList(
          new Query({
            query: [new QueryParam<ClientQuery>('ID', loggedUser.clinicID)],
          })
        )
        .subscribe((res) => {
          if (res && res.items.length > 0) {
            setClient(res.items[0])
          }
        })
    }
  }, [loggedUser])




//Relacionados con el check de los elementos para poder hacer el envio
  const handleCheckAll = () => {
    if (allChecked) {
      setAllChecked(false)
      setRequestsToSendNacex(
        requestsSendNacex.map((i) => {
          return {
            id: i.id,
            checked: false,
          }
        })
      )
    } else {
      setAllChecked(true)

      if (items?.length > 0) {
        setRequestsToSendNacex(
          allItems
            .filter((i) => i.country == 'spain' && client?.country == 'spain')
            ?.map((i) => {
              return {
                id: i.requestID,
                checked: true,
              }
            })
        )

        let item = items[0]
        setAddress({
          //@ts-ignore
          _id: uuidv4(),
          _requestID: item.requestID,
          _street: item.street,
          _number: item.number,
          _zipCode: item.zipCode,
          _city: item.city,
          _province: item.province,
          _date: new Date(),
          _hour: 0,
          _firstName: client?.taxName,
          _lastName: client?.taxIdentity,
          _phone: loggedUser.phone,
        })
      }
    }
  }

  const handleCheck = (item: RequestView) => {
    setAddress({
      //@ts-ignore
      _id: uuidv4(),
      _requestID: item.requestID,
      _street: item.street,
      _number: item.number,
      _zipCode: item.zipCode,
      _city: item.city,
      _province: item.province,
      _date: new Date(),
      _hour: 0,
      _firstName: item.taxName,
      _lastName: item.taxIdentity,
      _phone: loggedUser.phone,
    })

    const isSame = (aux: any) => aux.id === item.requestID
    let item2 = requestsSendNacex.findIndex(isSame)

    if (item2 !== -1) {
      let aux = [...requestsSendNacex]
      aux[item2].checked = !aux[item2].checked
      setRequestsToSendNacex(aux)
    } else {
      let aux = [...requestsSendNacex]
      aux.push({ id: item.requestID, checked: true })
      setRequestsToSendNacex(aux)
    }
  }



// campos de la tabla
const fields: Field<any>[] = [
  {
    searchable: true,
    label: t('date'),
    //@ts-ignore
    name: 'date',
    sortable: true,
    renderFunc: (field, item) =>
      item.date
        ? new Date(item.date.substring(0,item.date.toLocaleString().length - 1))
            .toLocaleString()
            .substring(0, new Date(item.date).toLocaleString().length - 3) + ' h'
        : '',
  },
  {
    searchable: true,
    label: t('client'),
    name: 'client',
    sortable: true,
    renderFunc: (field, item) => item.taxName,
  },
  {
    searchable: true,
    label: t('patient'),
    name: 'patient',
    sortable: true,
    renderFunc: (field, item) => item.name || '',
  },
  {
    searchable: true,
    sortable: false,
    label: t('codeSample'),
    name: 'request',
    renderFunc: (field, item) => item.codeSample || '',
  },
  {
    searchable: true,
    sortable: false,
    label: t('state'),
    //@ts-ignore
    name: 'state',
    renderFunc: (field, item) => t(item.status) || '',
  },
  {
    searchable: true,
    sortable: false,
    label: t('trackingState'),
    //@ts-ignore
    name: 'trackingState',
    renderFunc: (field, item) =>
      item.country === 'spain' ? t(item.trackingState) || '' : '',
  },
  {
    searchable: true,
    sortable: false,
    label: t('bioarrayCode'),
    //@ts-ignore
    name: 'bioarrayCode',
    renderFunc: (field, item) => item.newID || item.idOdoo || '' ,
  },
];

const fieldsUser: Field<any>[] = [
  {
    searchable: true,
    label: t('patientName'),
    //@ts-ignore
    name: 'id',
    renderFunc: (field, item) => item.name || '',
  },
  {
    searchable: true,
    label: t('date'),
    //@ts-ignore
    name: 'date',
    sortable: true,
    renderFunc: (field, item) =>
      item.date
        ? new Date(item.date)
            .toLocaleString()
            .substring(0, new Date(item.date).toLocaleString().length - 3) + ' h'
        : '',
  },
  {
    searchable: true,
    label: t('clinicHistory'),
    //@ts-ignore
    name: 'clinicHistory',
    sortable: true,
    //@ts-ignore
    renderFunc: (field, item) => item.patientID || '',
  },
  {
    searchable: true,
    sortable: true,
    label: t('codeSample'),
    name: 'request',
    renderFunc: (field, item) => item.codeSample || '',
  },
  {
    searchable: true,
    sortable: true,
    label: t('trackingState'),
    //@ts-ignore
    name: 'trackingState',
    renderFunc: (field, item) =>
      item.country === 'spain' ? t(item.trackingState) || '' : '',
  },
  {
    searchable: true,
    sortable: true,
    label: t('state'),
    //@ts-ignore
    name: 'state',
    renderFunc: (field, item) => t(item.status) || '',
  },
  {
    searchable: true,
    sortable: true,
    label: t('bioarrayCode'),
    //@ts-ignore
    name: 'bioarrayCode',
    renderFunc: (field, item) => item.newID || item.idOdoo || '',
  },
];


  const editPatient = (c2r: RequestView) =>
    navigate(URL_APPLICATION_EDIT.replace(':id', `${c2r.requestID}`))

  const downloadReport = (c2r: RequestView) => {
    fileService.downloadReport(c2r.requestID).subscribe((res) => {
      res && downloadFile(res.name, res.mimeType, res.base64)
    })
  }

  const downloadResults = (c2r: RequestView) => {
    requestService.downloadResults(c2r.requestID).subscribe((f) => {
      f && downloadFile(f.name, f.mimeType, f.base64)
    })
  }

  const actions: Actions<any> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editPatient,
        icon: editIcon,
        label: t('Edit'),
        hidden: (i) =>
          !authService.userCan(Permission.editPatients) || i.status != 'Eraser',
      },
      {
        handler: downloadReport,
        icon: downloadIcon,
        label: t('Download'),
        hidden: (i) => i.status != 'Sent',
      },
      {
        handler: downloadResults,
        icon: seeIcon,
        label: t('downloadResult'),
        hidden: (i) => !i.hasResult,
      },
    ],
  }

  const handleCreateRecogida = (pickUp: Address) => {
    if (
      !pickUp._street ||
      !pickUp._number ||
      !pickUp._zipCode ||
      !pickUp._city ||
      !pickUp._province ||
      !pickUp._hour ||
      !pickUp._phone ||
      !pickUp._firstName ||
      !pickUp._lastName ||
      //@ts-ignore
      !pickUp._numBultos
    ) {
      return
    }

    //@ts-ignore
    pickUp._numBultos = Number(pickUp._numBultos)

    let requestsIDs = requestsSendNacex.filter((i) => i.checked)?.map((a) => a.id)

    requestService.createPickupMultiple(pickUp, requestsIDs).subscribe((res) => {
      setRequestsToSendNacex([])
      setNacexModalOpened(false)
      enqueueSnackbar(t('pickupcorrectlycreated'), { variant: 'success' })
    })
  }

  const handleOpenNacexModal = () => {
    setNacexModalOpened(true)
  }
  
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>(""); 
  
  const filteredQuery = (searchValue: string, sortField: string, sortDesc: boolean, selectedStatus: string ) => {
  
    client2RequestService.getRequestsView(searchValue, loggedUser.id ,sortField, sortDesc, selectedStatus,page * itemsPerPage,
      itemsPerPage ).subscribe(
      (res) => {
        setItems(res.items);
        setCount(res.count);
      },
      );
  };


  
  const search: Search<any> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<any>[]) => {
      const result: SearchValue<any>[] = [];
      svs.forEach((s) => {
        result.push(s);
      });
      const searchValueTemp = result.length > 0 && result[0].value ? result[0].value.toLowerCase() : "";
      const statusValueTemp = result.length > 1 && result[1].value ? result[1].value : selectedStatus;
      

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
  
      debounceTimeout.current = setTimeout(() => {
        setSearchValue(searchValueTemp); 
        setSelectedStatus(statusValueTemp);
        filteredQuery(searchValueTemp, sort.field, sort.desc? true : false, statusValueTemp); 
        setSearcher(result);
      }, 500);
    },
  };
  
  const handleSort = (newSortField: string, newSortDesc: boolean) => {
    if (!loading) {
      filteredQuery(searchValue, newSortField, newSortDesc, selectedStatus); 
      setSort({ field: newSortField, desc: newSortDesc });
    }
  };
  
  const sortable: Sort<RequestQuery> = {
    name: sort.field,
    direction: sort.desc ?   'asc' :'desc',
    handleSort: (field: string) => {
      const newSortDesc = sort.field === field ? !sort.desc : true;
      setSort({ field: field, desc: newSortDesc });
      setIsLoading(true);
      
      handleSort(field, newSortDesc);
    },
  };
  

useEffect(() => {
  setIsLoading(true)

  if (itemsPerPage > count) {
    setPage(0)
  }

  setPager({
    page,
    count,
    handleChangePage: handlePaginationChange,
    rowsPerPage: itemsPerPage,
    handleChangeRowsPerPage,
  })
  //si la llamada es porque count ha cambiado no se hace la llamada
  filteredQuery(searchValue, sort.field, sort.desc? true : false, selectedStatus)

}, [page, itemsPerPage])

useEffect(() => {
  
  setPager({
    page,
    count,
    handleChangePage: handlePaginationChange,
    rowsPerPage: itemsPerPage,
    handleChangeRowsPerPage,
  })
  

}, [count])



const handlePaginationChange = (event: unknown, value: number) => setPage(value)

const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  if (Number.isNaN(event.target.value)) {
    setItemsPerPage(10)
    return
  }
  setItemsPerPage(Number.parseInt(event.target.value))
}

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {client?.country == 'spain' && (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleOpenNacexModal}
            disabled={requestsSendNacex.filter((i) => i.checked)?.length == 0}>
            {t('createPickup')}
          </Button>
        )}
      </div>
      <AppTable
        styleHeader={{ color: COLOR_APPLICATIONS }}
        actions={actions}
        fields={
          authService.userCan(Permission.seeRequestIDOnRequests) &&
          authService.userCan(Permission.seeClientOnRequests)
            ? fields
            : client?.country == 'spain'
            ? fieldsUser
            : fieldsUser.filter((i) => i.name != 'trackingState')
        }
        items={items}
        rowKeyField={'requestID'}
        pager={pager}
        sort={sortable}
        areRequests={true}
        handleCheck={handleCheck}
        handleCheckAll={handleCheckAll}
        search={search}
        requestsSendNacex={requestsSendNacex}
        allChecked={allChecked}
        selectedStatus={selectedStatus}
      />
      <Modal
        open={nacexModalOpened}
        onClose={() => setNacexModalOpened(!nacexModalOpened)}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ModalAddress
          handleModalClose={() => setNacexModalOpened(false)}
          handleCreateRecogida={handleCreateRecogida}
          pickup={address}
        />
      </Modal>
    </>
  )
}
